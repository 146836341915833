<template>
  <div class="home" v-if="show" ref="home">
    <!-- <div class="home-info"> -->
    <div class="top">
      <img class="logo" :src="brandUrl" alt="" />
      <div class="fanyi" @click="chooseLanguage">
        <img src="../assets/img/fanyi.png" alt="" />
        <span>{{ languageType }}</span>
      </div>
    </div>
    <div class="hotel-name">
      <span class="text word-break">{{ hotelName }}</span>
    </div>
    <div class="content" v-if="type == 'guest'">
      <van-swipe
        class="my-swipe"
        v-if="
          pageInfo['Hotel Highlight(s)'] &&
          pageInfo['Hotel Highlight(s)'].filter((i) => {
            return i.enable;
          }).length > 0
        "
        :autoplay="3000"
        indicator-color="white"
      >
        <template v-for="item in pageInfo['Hotel Highlight(s)']">
          <van-swipe-item v-if="item.enable" :key="item.id">
            <div
              class="swipe-area"
              @click="pdfView(item.fileUrl, item.title, false)"
            >
              <img :src="item.image" alt="" />
              <div class="mask" v-if="item.title2[language]!=' '">
                <span class="text word-break">{{ item.title2[language] }}</span>
              </div>
            </div>
          </van-swipe-item>
        </template>
      </van-swipe>
      <div class="room-requests">
        <div class="title" :class="{ right_content_title: language == 'ar' }">
          <span class="text word-break">{{ stringList.roomRequest }}</span>
        </div>
        <div class="info" v-if="language == 'es'">
          <div style="display: flex">
            <div class="info-item" style="width: 56%" @click="goToAmenities">
              <img src="../assets/img/Amenities.png" alt="" />
              <span>{{ stringList.amenities }}</span>
            </div>
            <div class="info-item" style="width: 44%" @click="goToServices">
              <img src="../assets/img/Cleaning Service.png" alt="" />
              <span>{{ stringList.services }}</span>
            </div>
          </div>
          <div style="display: flex; margin-top: 15px">
            <div
              class="info-item"
              style="width: 56%; margin-right: 15px"
              @click="goToMaintenance"
            >
              <img src="../assets/img/Maintenance.png" alt="" />
              <span>{{ stringList.maintenance }}</span>
            </div>
            <div style="width: 44%"></div>
          </div>
        </div>
        <div class="info" v-if="language == 'tl'">
          <div style="display: flex">
            <div class="info-item" style="width: 46%" @click="goToAmenities">
              <img src="../assets/img/Amenities.png" alt="" />
              <span>{{ stringList.amenities }}</span>
            </div>
            <div class="info-item" style="width: 54%" @click="goToServices">
              <img src="../assets/img/Cleaning Service.png" alt="" />
              <span>{{ stringList.services }}</span>
            </div>
          </div>
          <div style="display: flex; margin-top: 15px">
            <div
              class="info-item"
              style="width: 46%; margin-right: 15px"
              @click="goToMaintenance"
            >
              <img src="../assets/img/Maintenance.png" alt="" />
              <span>{{ stringList.maintenance }}</span>
            </div>
            <div style="width: 54%"></div>
          </div>
        </div>
        <div class="info" v-if="language == 'de'">
          <div style="display: flex">
            <div class="info-item" style="width: 52%" @click="goToAmenities">
              <img src="../assets/img/Amenities.png" alt="" />
              <span>{{ stringList.amenities }}</span>
            </div>
            <div class="info-item" style="width: 48%" @click="goToServices">
              <img src="../assets/img/Cleaning Service.png" alt="" />
              <span>{{ stringList.services }}</span>
            </div>
          </div>
          <div style="display: flex; margin-top: 15px">
            <div
              class="info-item"
              style="width: 52%; margin-right: 15px"
              @click="goToMaintenance"
            >
              <img src="../assets/img/Maintenance.png" alt="" />
              <span>{{ stringList.maintenance }}</span>
            </div>
            <div style="width: 48%"></div>
          </div>
        </div>
        <div class="info" v-if="language == 'en'" style="display: flex">
          <div
            class="info-item"
            style="min-width: 90px; flex: 1"
            @click="goToAmenities"
          >
            <img src="../assets/img/Amenities.png" alt="" />
            <span>{{ stringList.amenities }}</span>
          </div>
          <div class="info-item" style="flex: 1" @click="goToServices">
            <img src="../assets/img/Cleaning Service.png" alt="" />
            <span>{{ stringList.services }}</span>
          </div>
          <div
            class="info-item"
            style="min-width: 120px; flex: 1"
            @click="goToMaintenance"
          >
            <img src="../assets/img/Maintenance.png" alt="" />
            <span>{{ stringList.maintenance }}</span>
          </div>
        </div>
        <div class="info" v-if="language == 'ar'" style="display: flex">
          <div
            class="info-item"
            style="min-width: 120px; flex: 1"
            @click="goToAmenities"
          >
            <img src="../assets/img/Amenities.png" alt="" />
            <span>{{ stringList.amenities }}</span>
          </div>
          <div class="info-item" style="flex: 1" @click="goToServices">
            <img src="../assets/img/Cleaning Service.png" alt="" />
            <span>{{ stringList.services }}</span>
          </div>
          <div
            class="info-item"
            style="min-width: 90px; flex: 1"
            @click="goToMaintenance"
          >
            <img src="../assets/img/Maintenance.png" alt="" />
            <span>{{ stringList.maintenance }}</span>
          </div>
        </div>
        <div class="info" v-if="language == 'zh'" style="display: flex">
          <div
            class="info-item"
            style="min-width: 120px; flex: 1"
            @click="goToAmenities"
          >
            <img src="../assets/img/Amenities.png" alt="" />
            <span>{{ stringList.amenities }}</span>
          </div>
          <div class="info-item" style="flex: 1" @click="goToServices">
            <img src="../assets/img/Cleaning Service.png" alt="" />
            <span>{{ stringList.services }}</span>
          </div>
          <div
            class="info-item"
            style="min-width: 90px; flex: 1"
            @click="goToMaintenance"
          >
            <img src="../assets/img/Maintenance.png" alt="" />
            <span>{{ stringList.maintenance }}</span>
          </div>
        </div>
      </div>
      <div
        class="dine_drink"
        v-if="
          pageInfo['Dine & Drink'] &&
          pageInfo['Dine & Drink'].filter((i) => {
            return i.enable;
          }).length > 0
        "
      >
        <div class="title" :class="{ right_content_title: language == 'ar' }">
          <span class="text word-break">{{ stringList.dineDrink }}</span>
          <span
            @click="moveToRight('dineAndDrink')"
            class="text1 word-break"
            v-if="
              pageInfo['Dine & Drink'].filter((i) => {
                return i.enable;
              }).length > 3
            "
            >{{ stringList.more }}
            <img src="../assets/img/右箭头.png" style="width: 14px"
          /></span>
        </div>
        <div class="info" ref="dineAndDrink">
          <template v-for="item in pageInfo['Dine & Drink']">
            <div
              class="item"
              v-if="item.enable"
              :key="item.id"
              @click="pdfView(item.fileUrl, item.title, false)"
              :class="{ right_content_item: language == 'ar' }"
            >
              <img :src="item.image" alt="" />
              <div class="text" :class="{ right_text_item: language == 'ar' }">
                <span
                  class="text word-break"
                  :class="{ right_text_item: language == 'ar' }"
                  >{{ item.title2[language] }}</span
                >
              </div>
            </div>
          </template>
        </div>
      </div>
      <!--if pageInfo['Hotel Information'] 不为空而且，里面的item.enable都为true-->
      <div
        class="dine_drink"
        v-if="
          (hotelInno == 'chate' || hotelInno == 'CHATE') &&
          pageInfo['Things To Do'] &&
          pageInfo['Things To Do'].filter((i) => {
            return i.enable;
          }).length > 0
        "
      >
        <div class="title" :class="{ right_content_title: language == 'ar' }">
          <span class="text word-break">
            <span class="text word-break">{{ stringList.thingsToDo }}</span>
          </span>
          <span
            @click="moveToRight('tinksToDo')"
            class="text1 word-break"
            v-if="
              pageInfo['Things To Do'].filter((i) => {
                return i.enable;
              }).length > 3
            "
            >More <img src="../assets/img/右箭头.png" style="width: 14px"
          /></span>
        </div>
        <div class="info" ref="tinksToDo">
          <template v-for="item in pageInfo['Things To Do']">
            <div
              class="item"
              v-if="item.enable"
              :key="item.id"
              @click="pdfView(item.fileUrl, item.title, false)"
              :class="{ right_content_item: language == 'ar' }"
            >
              <img :src="item.image" alt="" />
              <div class="text" :class="{ right_text_item: language == 'ar' }">
                <span
                  class="text word-break"
                  :class="{ right_text_item: language == 'ar' }"
                  >{{ item.title2[language] }}</span
                >
              </div>
            </div>
          </template>
        </div>
      </div>
      <div
        class="hotel_information"
        v-if="
          (hotelInno != 'bdrst' && hotelInno != 'BDRST') ||
          (pageInfo['Hotel Information'] &&
            pageInfo['Hotel Information'].filter((i) => {
              return i.enable;
            }).length > 0)
        "
      >
        <div class="title" :class="{ right_content_title: language == 'ar' }">
          <span class="text word-break">{{ stringList.hotelInformation }}</span>
        </div>
        <div
          v-if="hotelInno != 'bdrst' && hotelInno != 'BDRST'"
          class="item"
          @click="goToInformation"
          :class="{ right_content_item: language == 'ar' }"
        >
          <img class="icon" src="../assets/img/pool.png" alt="" />
          <span>{{ stringList.facilitiesHours }}</span>
          <van-icon name="arrow" size="20" color="#BEBEBE" />
        </div>
        <template v-for="item in pageInfo['Hotel Information']">
          <div
            class="item"
            v-if="item.enable"
            :key="item.id"
            @click="pdfView(item.fileUrl, item.title, false)"
            :class="{ right_content_item: language == 'ar' }"
          >
            <img class="icon" src="../assets/img/hotel-info.png" alt="" />
            <span>{{ item.title2[language] }}</span>
            <van-icon name="arrow" size="20" color="#BEBEBE" />
          </div>
        </template>
      </div>
      <div
        class="dine_drink"
        v-if="
          hotelInno != 'chate' &&
          hotelInno != 'CHATE' &&
          pageInfo['Things To Do'] &&
          pageInfo['Things To Do'].filter((i) => {
            return i.enable;
          }).length > 0
        "
      >
        <div class="title" :class="{ right_content_title: language == 'ar' }">
          <span class="text word-break">
            <span class="text word-break">{{ stringList.thingsToDo }}</span>
          </span>
          <span
            @click="moveToRight('tinksToDo')"
            class="text1 word-break"
            v-if="
              pageInfo['Things To Do'].filter((i) => {
                return i.enable;
              }).length > 3
            "
            >More <img src="../assets/img/右箭头.png" style="width: 14px"
          /></span>
        </div>
        <div class="info" ref="tinksToDo">
          <template v-for="item in pageInfo['Things To Do']">
            <div
              class="item"
              v-if="item.enable"
              :key="item.id"
              @click="pdfView(item.fileUrl, item.title, false)"
              :class="{ right_content_item: language == 'ar' }"
            >
              <img :src="item.image" alt="" />
              <div class="text" :class="{ right_text_item: language == 'ar' }">
                <span
                  class="text word-break"
                  :class="{ right_text_item: language == 'ar' }"
                  >{{ item.title2[language] }}</span
                >
              </div>
            </div>
          </template>
        </div>
      </div>
      <div
        class="dine_drink"
        v-if="
          pageInfo['Other'] &&
          pageInfo['Other'].filter((i) => {
            return i.enable;
          }).length > 0
        "
      >
        <div class="title" :class="{ right_content_title: language == 'ar' }">
          <span class="text word-break">
            <span class="text word-break">{{ stringList.other }}</span>
          </span>
          <span
            @click="moveToRight('other')"
            class="text1 word-break"
            v-if="
              pageInfo['Other'].filter((i) => {
                return i.enable;
              }).length > 3
            "
            >More <img src="../assets/img/右箭头.png" style="width: 14px"
          /></span>
        </div>
        <div class="info" ref="other">
          <template v-for="item in pageInfo['Other']">
            <div
              class="item"
              v-if="item.enable"
              :key="item.id"
              @click="pdfView(item.fileUrl, item.title, false)"
              :class="{ right_content_item: language == 'ar' }"
            >
              <img :src="item.image" alt="" />
              <div class="text" :class="{ right_text_item: language == 'ar' }">
                <span
                  class="text word-break"
                  :class="{ right_text_item: language == 'ar' }"
                  >{{ item.title2[language] }}</span
                >
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="content" v-if="type == 'nonguest'"></div>
    <!-- </div> -->

    <div class="foot">
      <span class="text1">
        {{ address }}
      </span>
      <span class="text1">
        {{ phone }}
      </span>
      <span class="text2" style="display: flex; align-items: center">
        <img
          style="width: 9px; margin-right: 5px"
          src="../assets/logo2.png"
          alt=""
        /><span>{{ stringList.poweredBy }}</span>
      </span>
      <u
        v-if="showCheckList"
        style="margin: 10px 0px; font-size: 16px"
        @click="gotoChcekList()"
        >{{ stringList.checkList }} --></u
      >
    </div>
    <van-popup
      class="chose-language"
      v-model="showLanguageChoose"
      closeable
      :close-icon="require('../assets/img/close.png')"
      position="top"
      :close-on-click-overlay="true"
      :get-container="() => $refs.home"
    >
      <!-- 一级 -->
      <div class="pop">
        <div
          class="pop-title"
          :class="{ right_content_language_title: language == 'ar' }"
        >
          <span class="title">{{ stringList.language_select }}</span>
        </div>
        <div class="pop-container">
          <div
            class="pop-item"
            v-for="item in languageList"
            :key="item.value"
            @click="changeLanguage(item.value)"
            :class="{ 'pop-item-active': item.value == language }"
          >
            <span class="text" :class="{ active: item.value == language }">{{
              item.type
            }}</span>
            <span class="text2" :class="{ active: item.value == language }">{{
              item.name
            }}</span>
            <span
              class="text3"
              v-if="item.value == language"
              :class="{ active: item.value == language }"
              >√</span
            >
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getAction, deleteAction, putAction, postAction } from "@/api/manage";
import { mapGetters, mapActions } from "vuex";
import Vue from "vue";
import { randomUUID } from "@/utils/utils";
import { Toast } from "vant";
import lodash from "lodash";
import { Dialog } from "vant";
import moment from "moment-timezone";
import merge from "webpack-merge";
import { encryptedDES } from "@/utils/DES.js";

export default {
  metaInfo: {
    meta: [
      {
        name: "viewport",
        content:
          "width=device-width, initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no",
      },
    ],
  },
  name: "Home",
  components: {},
  data() {
    return {
      url: {
        login: "/api/common/user/hotel_dept_name/login",
        hotelQuery: "/api/sys_admin/hotel/query",
        hotelinfo: "/adminApi/hotelinfo/infobyhotel",
        getPageInfo: "/adminApi/file/page",
        queryRoomNum: "/api/sys_admin/hotel/room/query2/",
        getKeyWord: "/api/app/current_user/keywordsalldept/",
        getReplyList: "/adminApi/reply/listbyhotel",
        getIsInputPhone: "/adminApi/textconfig/list",
        createOrder: "/api/common/hotel/data/qrorder/create",
        getFileList: "/adminApi/file/filelist",
        getLanguageList: "/api/common/hotel/data/dict/by_key/languageQr",
        about: "/adminApi/test/qr/about1",
      },
      showLanguageChoose: false,
      brandUrl: "",
      hotelName: "",
      pageInfo: {},
      address: "",
      phone: "",
      rooms: [], //所有房间
      submiting: false,
      loading: null,
      show: false,
      languageList: Vue.ls.get("languageList"),
      language: Vue.ls.get("language"),
      languageType: Vue.ls.get("languageType"),
      stringList: {},
      hotelInno: "",
      type: "",
      tsp: 0,
      showPopWin: false,
      popWinPdfUrl: "",
      popWinPdftitle: "",
      showCheckList: false,
    };
  },
  computed: {
    ...mapGetters({
      amcniticsRequest: "amcniticsRequest", //Amcnitics的所有request
      informationRequest: "informationRequest",
    }),
  },
  async created() {
    this.type = this.$route.query.type;
    this.hotelInno = this.$route.query.inno;
    this.loading = Toast.loading({
      forbidClick: true,
      duration: 0,
    });
    this.show = false;
    console.log(this.$route);
    this.tsp = this.$route.query.tsp;
    let now = new Date().getTime();
    console.log(now - this.tsp * 1000);
    if (this.$route.query.room) {
      Vue.ls.set("room", this.$route.query.room, 1000 * 60 * 60 * 24);
    } else {
      Vue.ls.set("room", "");
    }
    if (now - this.tsp * 1000 > 1000 * 60 * 60 * 24) {
      this.loading.clear();
      //如果是走了这个about 后台调用一下接口 打log
      getAction(this.url.about).then((res) => {});
      this.$router.push({ name: "About" });
    } else {
      if (this.$route.query.type == "nonguest") {
        // meeting业务跳转
        this.$router.push({ name: "MeetingHome", query: this.$route.query });
      } else {
        if (Vue.ls.get("HTTOKEN")) {
          if (this.$route.query.inno != Vue.ls.get("inno")) {
            this.getToken();
          } else {
            this.getLanguageList();
            this.getHotelInfo();
            this.getAllRequest();
            this.getRoomNum();
            if (this.$route.query.type == "guest") {
              Vue.ls.set("type", this.$route.query.type, 1000 * 60 * 60 * 24);
            }
          }
        } else {
          this.getToken();
        }
      }
    }
    // if (Vue.ls.get("HTTOKEN")) {
    //   if (this.$route.query.code) {
    //     this.getToken();
    //   } else {
    //     if (this.$route.query.inno != Vue.ls.get("inno")) {
    //       this.getToken();
    //     } else {
    //       this.getLanguageList();
    //       this.getHotelInfo();
    //       this.getAllRequest();
    //       this.getRoomNum();
    //     }
    //   }
    // } else {
    //   if (this.$route.fullPath.indexOf("&code=") != -1) {
    //     this.getToken();
    //   } else {
    //     this.loading.clear();
    //     //如果是走了这个about 后台调用一下接口 打log
    //     getAction(this.url.about).then((res) => {});
    //     this.$router.push({ name: "About" });
    //   }
    // }
    if (!Vue.ls.get("language") || !Vue.ls.get("languageType")) {
      Vue.ls.set("language", "en");
      Vue.ls.set("languageType", "English");
      this.language = "en";
      this.languageType = "English";
    }
    let languageJsonName = "String_" + Vue.ls.get("language") + ".json";
    this.stringList = await import("@/data/" + languageJsonName);
    // if (this.$route.query.inno) {
    //   if (this.$route.query.inno != Vue.ls.get("inno")) {
    //     this.getToken();
    //   } else {
    //     if (Vue.ls.get("HTTOKEN")) {
    //       this.getHotelInfo();
    //       this.getAllRequest();
    //       this.getRoomNum();
    //     } else {
    //       this.getToken();
    //     }
    //   }
    // } else {
    //   this.loading.clear();
    //   this.$router.push({ name: "About" });
    // }
  },
  methods: {
    moment,
    ...mapActions([
      "editColor",
      "editAmcniticsRequest",
      "editInformationRequest",
      "editShowPhone",
      "editRoomList",
    ]),
    moveToRight(ref) {
      let scrollDiv = this.$refs[ref];
      this.$nextTick(() => {
        scrollDiv.scrollTo({
          left: 360,
          behavior: "smooth",
        });
      });
    },
    gotoChcekList() {
      let baseUrl = window.location.href.split("qrcode")[0];
      console.log(baseUrl);
      let url =
        baseUrl +
        "checklist.hotelservicebot.com?inno=" +
        this.hotelInno +
        "&roomNumber=" +
        //如果this.$route.query.room存在，就用this.$route.query.room，否则用''
        (this.$route.query.room ? this.$route.query.room : "");
      console.log(url);
      window.location.href = url;
    },
    chooseLanguage() {
      this.showLanguageChoose = true;
    },
    async changeLanguage(val) {
      let languageJsonName = "String_" + val + ".json";
      this.stringList = await import("@/data/" + languageJsonName);
      this.language = val;
      Vue.ls.set("language", val);
      this.languageType = this.languageList.filter((i) => {
        return i.value == val;
      })[0].type;
      Vue.ls.set("languageType", this.languageType);
      this.showLanguageChoose = false;
    },
    pdfView(inno, title, isPopWin) {
      let urlType = inno.split(".")[inno.split(".").length - 1];

      //创建订单
      let now = new Date();
      let createTime = moment(now).tz(Vue.ls.get("timeZone"));
      let time = new Date().valueOf().toString();
      let params = {
        createTime: createTime.utc().format(),
        fdaction: "",
        fdhour: Vue.ls.get("overNightHoursInfo"),
        hotelId: Vue.ls.get("hotelId"),
        hotelDeptId: "",
        inquiryNum: "",
        notes: "",
        requestType: Vue.ls.get("type"),
        roomNumber: Vue.ls.get("room") ? Vue.ls.get("room") : "GSD",
        requestTime: createTime.utc().format(),
        serviceKey: title ? title : "Guest Service Directory",
        serviceType: "gsd",
        createTimeValue: time,
        tid: encryptedDES(time.substring(time.length - 8)),
      };

      if (!this.submiting) {
        this.submiting = true;
        postAction(this.url.createOrder, params)
          .then((res) => {
            if (res.success) {
              this.submiting = false;
              if (
                urlType == "xlsx" ||
                urlType == "docx" ||
                urlType == "pptx" ||
                urlType == "xls" ||
                urlType == "doc" ||
                urlType == "ppt"
              ) {
                window.location.href =
                  "https://view.officeapps.live.com/op/view.aspx?src=" + inno;
              } else if (
                urlType == "jpg" ||
                urlType == "png" ||
                urlType == "jpeg" ||
                urlType == "gif"
              ) {
                window.location.href = inno;
              } else if (urlType == "pdf") {
                this.$router.push({
                  name: "PdfView",
                  query: { inno: inno, isPopWin: isPopWin },
                });
              } else {
                if (inno.indexOf("home?hotelname") != -1) {
                  if (this.$route.query.room) {
                    window.location.href =
                      inno + "&room=" + this.$route.query.room;
                  } else {
                    window.location.href = inno;
                  }
                } else {
                  window.location.href = inno;
                }
              }
            } else {
              this.submiting = false;
            }
          })
          .catch((e) => {
            this.submiting = false;
          });
      }
    },
    getIsInputPhone() {
      let form = {
        hotelId: Vue.ls.get("hotelId"),
        size: 10,
        current: 1,
      };
      postAction(this.url.getIsInputPhone, form).then((res) => {
        if (res.status == 0) {
          res.data.records.forEach((item) => {
            if (Vue.ls.get("type") == "guest") {
              if (item.requestType == "Guest Room Request") {
                if (item.textResponse == "Enable") {
                  this.editShowPhone(true);
                  return;
                }
              }
            } else if (Vue.ls.get("type") == "nonguest") {
              if (item.requestType == "Meeting Room Request") {
                if (item.textResponse == "Enable") {
                  this.editShowPhone(true);
                  return;
                }
              }
            }
          });
        }
      });
    },
    getReplyList() {
      let params = {
        hotelId: Vue.ls.get("hotelId"),
        type: "internal",
      };
      getAction(this.url.getReplyList, params).then((res) => {
        if (res.status == 0) {
          for (let element of res.data) {
            if (element.botTrigger == "556" || element.botTrigger == "557") {
              Vue.ls.set(
                "fdOption",
                element.botTriggerValue,
                1000 * 60 * 60 * 24
              );
              Vue.ls.set(
                "fdOptionReponse",
                element.botResponse2,
                1000 * 60 * 60 * 24
              );
              break;
            }
          }
          for (let element of res.data) {
            if (element.botTrigger == "677") {
              Vue.ls.set(
                "engHourReponse",
                element.botResponse2,
                1000 * 60 * 60 * 24
              );
              break;
            }
          }
        }
      });
    },
    getAllRequest() {
      if (this.amcniticsRequest.length == 0) {
        getAction(`${this.url.getKeyWord}${"fdkey"}`).then((res) => {
          if (res.status == 0) {
            this.editAmcniticsRequest(res.data);
          }
        });
      }
      if (this.informationRequest.length == 0) {
        getAction(`${this.url.getKeyWord}${"nonfdkey"}`).then((res) => {
          if (res.status == 0) {
            this.editInformationRequest(res.data);
          }
        });
      }
    },
    getRoomNum() {
      if (Vue.ls.get("type") == "guest") {
        getAction(`${this.url.queryRoomNum}${"guest"}/${"allmeeting"}`).then(
          (res) => {
            if (res.status == 0) {
              this.editRoomList(res.data);
            }
          }
        );
      }
    },
    getLanguageList() {
      getAction(this.url.getLanguageList).then((res) => {
        this.languageList = res.data;
        Vue.ls.set("languageList", res.data);
      });
    },
    getToken() {
      let params = {
        hotelDeptName: "qrbot",
        hotelName: this.$route.query.inno,
        password: "123456",
      };
      postAction(this.url.login, params).then((res) => {
        if (res.status == 0) {
          this.token = res.data.token;
          // Vue.ls.set("HTTOKEN", res.data.token, 1000 * 60 * 60 * 24);
          Vue.ls.set("HTTOKEN", res.data.token);
          // this.$router.push({
          //   query: merge(this.$route.query, { code: "" }),
          // });
          // let query = {};
          // if (this.$route.query.type) {
          //   query.type = this.$route.query.type;
          // }
          // query.inno = this.$route.query.inno;
          // if (this.$route.query.room) {
          //   query.room = this.$route.query.room;
          // }
          // query.noCode = "";
          // setTimeout(() => {
          //   this.$router.replace({ name: "Home", query: query });
          // }, 1000 * 60 * 5);
          this.getLanguageList();
          this.getHotelInfo();
          this.getAllRequest();
          if (this.$route.query.type) {
            Vue.ls.set("type", this.$route.query.type, 1000 * 60 * 60 * 24);
            this.getRoomNum();
          }
          if (this.$route.query.inno) {
            Vue.ls.set("inno", this.$route.query.inno, 1000 * 60 * 60 * 24);
          }
        }
      });
    },
    getHotelInfo() {
      postAction(this.url.hotelQuery, { hotelNo: this.$route.query.inno }).then(
        (res) => {
          if (res.status == 0) {
            this.hotelName = res.data.name;
            Vue.ls.set("timeZone", res.data.timeZone, 1000 * 60 * 60 * 24);
            Vue.ls.set("hotelId", res.data.id, 1000 * 60 * 60 * 24);
            //判断是否启动夜间模式
            Vue.ls.set(
              "overNightHoursInfo",
              res.data.overNightHours,
              1000 * 60 * 60 * 24
            );
            if (
              res.data.overNightHours == "disable" ||
              res.data.overNightHours == "Regular Operating Hour"
            ) {
              Vue.ls.set("overNightHours", false, 1000 * 60 * 60 * 24);
            } else if(res.data.overNightHours=="Cycle Regular Operating Hour")  {
              // Vue.ls.set("overNightHours", false, 1000 * 60 * 60 * 24);
              let now = new Date();
              let taody = moment(now)
                .tz(res.data.timeZone)
                .format("YYYY/MM/DD");
              let startTime = moment.tz(
                moment(taody + " " + res.data.fdStartTime).format(
                  "YYYY-MM-DD HH:mm"
                ),
                res.data.timeZone
              );
              let endTime = moment.tz(
                moment(
                  res.data.fdEndTime.replace("tomorrow_", taody + " ")
                ).format("YYYY-MM-DD HH:mm"),
                res.data.timeZone
              );
              if (this.getIsInTimeRange(startTime, endTime)) {
                Vue.ls.set("overNightHours", true, 1000 * 60 * 60 * 24);
              } else {
                Vue.ls.set("overNightHours", false, 1000 * 60 * 60 * 24);
              }
            }else{
               Vue.ls.set("overNightHours", true, 1000 * 60 * 60 * 24);
            }
            Vue.ls.set(
              "fdStartTime",
              res.data.fdStartTime,
              1000 * 60 * 60 * 24
            );
            Vue.ls.set("fdEndTime", res.data.fdEndTime, 1000 * 60 * 60 * 24);

            Vue.ls.set("engHour", res.data.engHour, 1000 * 60 * 60 * 24);
            Vue.ls.set("engEndTime", res.data.engEndTime, 1000 * 60 * 60 * 24);
            Vue.ls.set(
              "engStartTime",
              res.data.engStartTime,
              1000 * 60 * 60 * 24
            );
            Vue.ls.set(
              "engRequestTime",
              res.data.engRequestTime,
              1000 * 60 * 60 * 24
            );
            getAction(this.url.hotelinfo, { hotelId: res.data.id }).then(
              (r) => {
                if (r.status == 0) {
                  this.editColor("#" + r.data.color);
                  this.show = true;
                  this.brandUrl =
                    "https://hotelfile353485474178.s3-accelerate.amazonaws.com/logo/" +
                    r.data.brand.toLowerCase().replaceAll(" ", "_") +
                    ".png";
                  this.phone = r.data.phone;
                  this.showCheckList =
                    r.data.openMenu.indexOf("checkList") != -1;
                  this.address =
                    r.data.address +
                    ", " +
                    r.data.city +
                    ", " +
                    r.data.state +
                    " " +
                    r.data.zipcode +
                    ", USA";
                }
              }
            );
            getAction(this.url.getPageInfo, { hotelId: res.data.id }).then(
              (r) => {
                if (r.status == 0) {
                  this.pageInfo = r.data;
                  if (this.pageInfo["Other"]) {
                    for (let element of this.pageInfo["Other"]) {
                      element.title2 = JSON.parse(element.title2);
                    }
                  }
                  if (this.pageInfo["Things To Do"]) {
                    for (let element of this.pageInfo["Things To Do"]) {
                      element.title2 = JSON.parse(element.title2);
                    }
                  }
                  if (this.pageInfo["Hotel Information"]) {
                    for (let element of this.pageInfo["Hotel Information"]) {
                      element.title2 = JSON.parse(element.title2);
                    }
                  }
                  if (this.pageInfo["Hotel Highlight(s)"]) {
                    for (let element of this.pageInfo["Hotel Highlight(s)"]) {
                      element.title2 = JSON.parse(element.title2);
                    }
                  }
                  if (this.pageInfo["Dine & Drink"]) {
                    for (let element of this.pageInfo["Dine & Drink"]) {
                      element.title2 = JSON.parse(element.title2);
                    }
                  }
                  if (this.pageInfo["On Pop Up Page"]) {
                    for (
                      let i = 0;
                      i < this.pageInfo["On Pop Up Page"].length;
                      i++
                    ) {
                      if (this.pageInfo["On Pop Up Page"][i].enable) {
                        this.popWinPdftitle =
                          this.pageInfo["On Pop Up Page"][i].title;
                        this.popWinPdfUrl =
                          this.pageInfo["On Pop Up Page"][i].fileUrl;
                        if (this.popWinPdfUrl != "" && this.$route.query.code) {
                          var url = new URL(window.location.href);
                          url.searchParams.delete("code");
                          window.history.replaceState({}, "", url);
                          this.pdfView(
                            this.popWinPdfUrl,
                            this.popWinPdftitle,
                            true
                          );
                        }
                      }
                    }
                  }
                  this.loading.clear();
                  console.log(this.pageInfo);
                }
              }
            );
            postAction(this.url.getFileList, { hotelId: res.data.id }).then(
              (r) => {
                if (r.status == 0) {
                  Vue.ls.set(
                    "fileList",
                    r.data.htFileList,
                    1000 * 60 * 60 * 24
                  );
                }
              }
            );

            this.getReplyList();
            this.getIsInputPhone();
          }
        }
      );
    },
    getIsInTimeRange(startTime, endTime) {
      let now = new Date();
      if (startTime.valueOf() < endTime.valueOf()) {
        return (
          startTime.valueOf() <= now.getTime() &&
          now.getTime() <= endTime.valueOf()
        );
      } else {
        return (
          startTime.valueOf() <= now.getTime() ||
          now.getTime() <= endTime.valueOf()
        );
      }
    },
    goToAmenities() {
      let query = {};
      query.type = this.$route.query.type;
      query.inno = this.$route.query.inno;
      if (this.$route.query.room) {
        query.room = this.$route.query.room;
      }
      query.tsp = this.$route.query.tsp;
      this.$router.push({ name: "Amenity", query: query });
    },
    goToServices() {
      let query = {};
      query.type = this.$route.query.type;
      query.inno = this.$route.query.inno;
      if (this.$route.query.room) {
        query.room = this.$route.query.room;
      }
      query.tsp = this.$route.query.tsp;
      this.$router.push({ name: "Service", query: query });
    },
    goToMaintenance() {
      let query = {};
      query.type = this.$route.query.type;
      query.inno = this.$route.query.inno;
      if (this.$route.query.room) {
        query.room = this.$route.query.room;
      }
      query.tsp = this.$route.query.tsp;
      this.$router.push({ name: "Maintenance", query: query });
    },
    goToInformation() {
      let query = {};
      query.type = this.$route.query.type;
      query.inno = this.$route.query.inno;
      if (this.$route.query.room) {
        query.room = this.$route.query.room;
      }
      query.tsp = this.$route.query.tsp;
      this.$router.push({ name: "Information", query: query });
    },
  },
};
</script>
<style lang="scss" scoped>
.right_content_language_title {
  padding-left: 80px;
}
.right_text_item {
  text-align: right !important;
  display: grid !important;
}
// .title_mini_font {
//   span {
//     font-size: 13px !important;
//   }
// }
// .title_mini_font2 {
//   span {
//     font-size: 12px !important;
//   }
// }
.home {
  text-align: left;
  width: 100%;
  // position: relative;
  // height: 100%;
  // .home-info::-webkit-scrollbar {
  //   display: none;
  // }
  // .home-info {
  //   height: calc(100% - 68px);
  //   overflow: auto;
  //   background-color: #ffffff;
  .word-break {
    word-break: normal;
  }
  .top {
    height: 60px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;

    .logo {
      // width: 103px;
      height: 42px;
    }

    .fanyi {
      height: 28px;
      padding: 4px 12px;
      border-radius: 16px;
      // border: 1px solid #e8e8e8;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #e8e8e8;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
        margin-right: 6px;
      }
      span {
        font-size: 13px;
      }
    }
  }
  .hotel-name {
    min-height: 40px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    .text {
      color: $primary-color;
      font-size: 20px;
      font-family: $fontFamily;
      line-height: 22px;
    }
  }

  .content {
    padding: $home-padding15 20px;
    background-color: #ffffff;
    border-bottom: 1px solid #e8e8e8;

    .my-swipe {
      margin-bottom: $home-padding15;
      .van-swipe-item {
        border-radius: 6px;

        .swipe-area {
          position: relative;
          img {
            height: 120px;
            width: 100%;
            border-radius: 12px;
          }
          .mask {
            position: absolute;
            min-height: 24px;
            padding: 3px 10px;
            width: 100%;
            top: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(
              to right,
              rgba(255, 255, 255, 0.35),
              rgba(255, 255, 255, 0.85) 30%,
              rgba(255, 255, 255, 0.85) 70%,
              rgba(255, 255, 255, 0.35)
            );
            // background: linear-gradient(
            //   to right,
            //   rgba(255, 255, 255, 0.35),
            //   rgba(255, 255, 255, 0.85),
            //   rgba(255, 255, 255, 0.35)
            // );

            .text {
              color: #000000;
              font-size: 22px;
              font-family: $fontFamily;
              line-height: 24px;
            }
          }
        }
      }
    }

    .room-requests {
      // margin-top: $home-padding15;
      // height: 98px;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      .title {
        height: 30px;
        display: flex;
        align-items: center;
        .text {
          line-height: 18px;
          // color: #4f4f4f;
          color: #000;
          font-size: 16px;
          font-family: $fontFamily;
          font-weight: 700;
        }
      }

      .info {
        // height: 60px;
        // display: flex;
        align-items: center;
        // justify-content: space-between;

        .info-item {
          height: 70px;
          display: flex;
          flex-flow: column;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          border: 1px solid #e8e8e8;
          // padding: 0 18px;
          margin-right: 15px;
          span {
            // height: 25px;
            // color: #4f4f4f;
            color: #000;
            font-size: 15px;
            line-height: 17px;
            font-family: $fontFamily;
            display: flex;
            align-items: center;
            margin-top: 3px;
            text-align: center;
          }
          img {
            height: 25px;
          }
        }

        .info-item:last-child {
          margin-right: 0px;
        }
      }
    }

    .dine_drink {
      margin-top: $home-padding15;
      .title {
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 2px solid #e8e8e8;
        margin-bottom: $home-title-margin-bottom10;
        .text {
          line-height: 18px;
          // color: #4f4f4f;
          color: #000;
          font-size: 16px;
          font-family: $fontFamily;
          font-weight: 700;
        }

        .text1 {
          width: 70px;
          text-align: right;
          line-height: 15px;
          // color: #4f4f4f;
          color: #000;
          font-size: 13px;
          font-family: $fontFamily;
        }
      }

      .info::-webkit-scrollbar {
        display: none;
      }

      .info {
        display: flex;
        align-items: start;
        // justify-content: space-between;
        overflow-x: auto;
        overflow-y: hidden;

        .item {
          width: 120px;
          // height: 105px;
          flex-shrink: 0;
          margin-right: 15px;
          border-radius: 5px;

          img {
            height: 65px;
            width: 100%;
            border-radius: 5px;
          }

          .text {
            // height: 40px;
            margin-top: 5px;
            // color: #4f4f4f;
            color: #000;
            font-size: 15px;
            font-family: $fontFamily;
            line-height: 19px;
            display: flex;
            // align-items: center;
            margin-left: 6px;
            // justify-content: center;
          }
        }

        .item:last-child {
          margin-right: 0;
        }
      }

      // .info::-webkit-scrollbar {
      //   display: none; /* Chrome Safari */
      // }
    }

    .hotel_information {
      margin-top: $home-padding15;

      .title {
        height: 30px;
        display: flex;
        align-items: center;
        border-bottom: 2px solid #e8e8e8;
        .text {
          line-height: 18px;
          // color: #4f4f4f;
          color: #000;
          font-size: 16px;
          font-family: $fontFamily;
          font-weight: 700;
        }
      }

      .item {
        height: 50px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e8e8e8;
        padding: 0 10px;

        .icon {
          height: 22px;
          margin-right: 30px;
        }

        span {
          flex: 1;
          line-height: 17px;
          // color: #4f4f4f;
          color: #000;
          font-size: 15px;
          font-family: $fontFamily;
        }
      }

      .item:last-child {
        border-bottom: 0px;
      }
    }
  }
  // }

  .foot {
    min-height: 68px;
    display: flex;
    flex-flow: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: #f8f8f8;
    padding: 10px 20px;
    // position: absolute;
    // bottom: 0;
    // width: 100%;
    // border-top: 1px solid #e8e8e8;

    .text1 {
      font-family: $fontFamily;
      color: #101010;
      font-size: 12px;
      line-height: 14px;
      // width: 298px;
      margin-bottom: 5px;
    }
    .text2 {
      font-family: $fontFamily;
      color: #9a9a9a;
      font-size: 12px;
      line-height: 14px;
      // width: 298px;
    }
  }
}
.right_content_title {
  text-align: right !important;
  display: block !important;
}
.right_content_item {
  text-align: right !important;
}
.pop {
  word-break: normal;
  text-align: left;
  max-height: 80vh;

  .pop-title {
    margin: 0 30px;
    height: 60px;
    padding-top: 20px;
    display: flex;
    align-items: center;
    // justify-content: center;
    text-align: left;
    position: relative;
    // border-bottom: 2px solid #e8e8e8;

    .title {
      font-family: $fontFamily;
      font-size: 18px;
      line-height: 20px;
      // color: #1e4380;
      font-weight: 600;
    }

    .icon {
      font-size: 22px;
      color: #bbbbbb;
      position: absolute;
      left: 0px;
    }

    .shopping {
      color: #bbbbbb;
      position: absolute;
      right: 0px;
    }

    .van-badge__wrapper {
      display: flex;
    }

    /deep/.van-badge--fixed {
      top: 5px;
    }

    /deep/.van-badge {
      font-family: $fontFamily;
    }
  }

  .pop-title3 {
    margin: 0 20px;
    padding-bottom: 10px;
    height: 70px;
    display: flex;
    align-items: end;
    position: relative;
    border-bottom: 2px solid #e8e8e8;

    .title {
      font-family: $fontFamily;
      font-size: 20px;
      line-height: 22px;
      color: #1e4380;
      font-weight: 600;
    }
  }

  .pop-title2 {
    margin: 0 20px;
    padding-bottom: 10px;
    height: 70px;
    display: flex;
    align-items: end;
    justify-content: center;
    position: relative;
    border-bottom: 2px solid #e8e8e8;

    .title {
      font-family: $fontFamily;
      font-size: 20px;
      line-height: 22px;
      color: #1e4380;
      font-weight: 600;
    }
  }

  .pop-container {
    // padding: 0 30px;
    max-height: calc(80vh - 160px);
    overflow-y: auto;
    .pop-item-active {
      background: #fbfafa;
    }
    .pop-item {
      min-height: 50px;
      padding: 10px 30px;
      // border-top: 1px solid #e8e8e8;
      display: flex;
      align-items: center;
      // justify-content: center;
      text-align: left;
      .active {
        color: #2b5dad !important;
      }
      img {
        height: 22px;
        margin-right: 25px;
      }
      .text {
        color: #000000;
        font-size: 15px;
        line-height: 17px;
        font-family: $fontFamily;
        width: 80px;
      }
      .text2 {
        flex: 0.9;
        color: #555;
        font-size: 13px;
        line-height: 17px;
        font-family: $fontFamily;
      }
      .text3 {
        font-size: 20px;
        line-height: 17px;
        font-family: $fontFamily;
      }
      .icon {
        font-size: 18px;
        color: #bbbbbb;
      }

      &:first-child {
        border-top: 0px;
      }
    }

    .other-select {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      padding-left: 50px;

      .select-other {
        flex: 1;
        font-size: 15px;
        font-family: $fontFamily;
      }

      /deep/.ant-select-selection {
        border-color: transparent;
        background-color: #f3f3f3;
        border-radius: 6px;
        box-shadow: none !important;
      }
      /deep/.ant-select-selection__placeholder {
        color: #9a9a9a;
      }
      /deep/.ant-select-selection:hover {
        border-color: transparent;
        box-shadow: 0 0 0 1px #9a94c0 !important;
      }
    }

    .van-stepper {
      display: flex;
      flex-flow: nowrap;
      align-items: center;
      justify-content: center;
      margin-left: 20px;
    }

    /deep/.van-stepper--round .van-stepper__minus {
      background-color: #ffffff;
      color: #4f4f4f;
      border: 2px solid #4f4f4f;
    }

    /deep/.van-stepper--round .van-stepper__plus {
      background-color: #ffffff;
      color: #4f4f4f;
      border: 2px solid #4f4f4f;
    }

    /deep/.van-stepper__minus::before,
    /deep/.van-stepper__plus::before {
      height: 2px !important;
    }

    /deep/.van-stepper__minus::after,
    /deep/.van-stepper__plus::after {
      width: 2px !important;
    }

    /deep/.van-stepper--round .van-stepper__input {
      font-family: $fontFamily;
      font-size: 16px;
      // line-height: 22px;
      height: 24px !important;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .btn-area {
    height: 100px;
    border-top: 2px solid #e8e8e8;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
      height: 47px;
      width: 167px;
      border-radius: 20px;
      background-color: $primary-color;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 16px;
        line-height: 18px;
        font-family: $fontFamily;
        color: #ffffff;
      }
    }
  }

  .pop-info {
    padding: 0 40px;
    .text {
      font-size: 15px;
      line-height: 19px;
      color: #000000;
      font-family: $fontFamily;
      margin-bottom: 10px;
    }

    .info-item {
      padding-left: 20px;
      font-size: 15px;
      line-height: 20px;
      color: #000000;
      font-family: $fontFamily;
      position: relative;

      &:before {
        position: absolute;
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: $primary-color;
        left: 0px;
        top: 7px;
      }
    }

    .info-item:last-child {
      margin-bottom: 20px;
    }

    /deep/.van-radio__label {
      font-size: 15px;
      line-height: 17px;
      color: #333333;
      font-family: $fontFamily;
    }

    /deep/.van-radio__icon--checked .van-icon {
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }
}
/deep/.van-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}
.van-popup--bottom.van-popup--round {
  border-radius: 12px 12px 0 0;
}

.van-popup--center.van-popup--round {
  border-radius: 6px;
}
/deep/.chose-language {
  .van-icon__image {
    width: 18px;
    height: 18px;
    margin: 9px;
  }
}
</style>
